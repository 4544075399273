import React from 'react'

import './certification.css'

import awsSAACertificationIcon from '../../assets/images/AWS-SolArchitect-Associate.png'
import azureFundamentalsCertifcationIcon from '../../assets/images/azure-fundamentals-600x600.png'
import azureDataFundamentalsCertifcationIcon from '../../assets/images/azure-data-fundamentals-600x600.png'
import gcpPCACertificationIcon from '../../assets/images/GCP-PCA.png'

const Certification = () => (
  <div className="certification">
    <ul>
      <li>
        <a
          href="https://www.credly.com/badges/a5c17a55-de8c-4625-aa15-f93094ddc919/public_url"
          id="gcp-pca-certification-link"
          target="_blank"
          rel="noreferrer"
          name="Girija's GCP PCA Credentials"
          title="See Girija's GCP PCA Credentials"
        >
          <img
            className="badge"
            src={gcpPCACertificationIcon}
            alt="Girija's AWS SA certification badge"
          />
        </a>
      </li>

      <li>
        <a
          href="https://www.credly.com/badges/c927fef9-e5ee-4e73-a1c5-dfbb5dd31d40/public_url"
          id="aws-sa-certification-link"
          target="_blank"
          rel="noreferrer"
          name="Girija's AWS SA Credentials"
          title="See Girija's AWS SA Credentials"
        >
          <img
            className="badge"
            src={awsSAACertificationIcon}
            alt="Girija's AWS SA certification badge"
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.credly.com/badges/6f8283a0-a895-4d7c-8d9a-5368d1433248/public_url"
          id="azure-fundamentals-certification-link"
          target="_blank"
          rel="noreferrer"
          name="Girija's Microsoft Azure Fundamentals Credentials"
          title="See Girija's Microsoft Azure Fundamentals Credentials"
        >
          <img
            className="badge"
            src={azureFundamentalsCertifcationIcon}
            alt="Girija's Azure Fundamentals certification badge"
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.credly.com/badges/8ce8dc6e-cd1c-4dfb-8979-eea208ffd2ab/public_url"
          id="azure-data-fundamentals-certification-link"
          target="_blank"
          rel="noreferrer"
          name="Girija's Microsoft Azure Data Fundamentals Credentials"
          title="See Girija's Microsoft Azure Data Fundamentals Credentials"
        >
          <img
            className="badge"
            src={azureDataFundamentalsCertifcationIcon}
            alt="Girija's Azure Data Fundamentals certification badge"
          />
        </a>
      </li>
    </ul>
  </div>
)

export default Certification
