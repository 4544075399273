import React from 'react'
import './shortnote.css'

const ShortNote = () => (
  <section className="shortnote">
    <ul className="shortnote-list">
      <li>
        My way to{' '}
        <a
          href="https://stackoverflow.com/users/4601120/gsswain"
          target="_blank"
          rel="noreferrer"
          name="Girija's Stack Overflow profile"
          title="Visit Girija's Stack Overflow profile"
        >
          give back{' '}
        </a>{' '}
        to the software engineering community.
      </li>

      <li>
        I{' '}
        <a
          href="https://gsswain.medium.com/"
          target="_blank"
          rel="noreferrer"
          name="Girija's Posts on Medium"
          title="Visit Girija's Medium page"
        >
          write
        </a>{' '}
        very occasionally.{' '}
      </li>

      <li>
        <a
          href="https://www.linkedin.com/in/gsswain"
          target="_blank"
          rel="noreferrer"
          name="Girija's Linkedin profile"
          title="Visit Girija's Linkedin profile"
        >
          {' '}
          Here{' '}
        </a>{' '}
        is my professional experience summary.
      </li>
      <li>
        {' '}
        If you want to reach out to me over an{' '}
        <a
          href="mailto:contact@gsswain.com"
          name="Girija's email"
          title="Send an email to Girija"
        >
          email
        </a>
        .
      </li>
    </ul>
  </section>
)

export default ShortNote
